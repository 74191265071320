import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";

export interface CreateUserType {
    id: number;
    name: string;
    status: boolean;
    cnpj: string;
    email: string;
    network: string;
    videoLink?: string;
    consultingHours?: number;
    hasConvenience?: boolean;
}

export default function useUser() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [listUsers, setListUsers] = useState<CreateUserType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListUsers, setInitialListUsers] = useState<CreateUserType[]>([]);

    // async function createUser(userData: CreateUserType): Promise<CreateUserType | null> {
    //     setLoading(true);
    //     try {
    //         let response = await api.post(`/Empresas/create`, userData);
    //         console.log(response, "response");
    //         console.log(response.data, "response.data");
    //         setLoading(false);
    //         return response.data;
    //     } catch (error) {
    //         setLoading(false);
    //         console.error("Erro ao criar usuário:", error);
    //         return null;
    //     }
    // }

    async function createUser(userData: CreateUserType): Promise<CreateUserType | null> {
        setLoading(true);
        try {
            const response = await api.post(`/Empresas/create`, userData);
            setLoading(false);
            showToast("success", "Empresas cadastrada com sucesso!");
            return response.data;
        } catch (error: any) {
            console.log(error);
            setLoading(false);
            showToast("error", "Houve um erro ao cadastrar a empresa, tente novamente.", error);
            let errorMessage = "Erro desconhecido ao criar usuário";
            if (error.response && error.response.data) {
                errorMessage = error.response.data || "Erro ao processar os dados fornecidos";
            }
            throw new Error(errorMessage);
        }
    }
    

    async function updateUser(userData: CreateUserType): Promise<CreateUserType | null> {
        setLoading(true);
        try {
            const response = await api.put(`/Empresas/update?idEmpresa=${userData.id}`, userData);
            setLoading(false);
            showToast("success", "Empresas atualizada com sucesso!");
            return response.data;
        } catch (error) {
            setLoading(false);
            showToast("error", "Houve um erro ao editar a empresa, tente novamente.", error);
            console.error("Erro ao atualizar usuário:", error);
            return null;
        }
    }

    async function deleteEmpresa(empresaId: number) {
        setLoading(true);
        try {
            const response = await api.delete(`/Empresas/delete/${empresaId}`);
            setLoading(false);
            showToast("success", "Empresas excluída com sucesso!");
            return response.data;
        } catch (error: any) {
            setLoading(false);
            showToast("error", "Houve um erro ao excluir a empresa, tente novamente.", error);
            console.error("Erro ao excluir empresa:", error);
            throw new Error(error.response.data || "Erro desconhecido ao excluir empresa");
        }
    }
    
    async function toggleEmpresaStatus(empresaId: number) {
        setLoading(true);
        try {
            const response = await api.post(`/Empresas/toggle-status/${empresaId}`);
            setLoading(false);
            showToast("success", "Status da empresa alterado com sucesso!");
            return response.data;
        } catch (error: any) {
            setLoading(false);
            showToast("error", "Houve um erro ao altera o status da empresa, tente novamente.", error);
            throw new Error(error.response.data.message || "Erro desconhecido ao alternar status");
        }
    }
    

    async function getAllUsers(
        pageNumber: number,
        name?: string,
        network?: string
    ) {
        setLoading(true);

        let url = `/Empresas/getEmpresasPaged?pageNumber=${pageNumber}&pageSize=10`;

        if (name) {
            url = url + `&name=${name}`;
        }
        if (network) {
            url = url + `&network=${network}`;
        }

        try {
            const response = await api.get(url);
            setListUsers(response.data.result);
            setInitialListUsers(response.data.result);
            setPageCount(response.data.pageCount);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar usuários:", error);
            return null;
        }
    }

    return { 
        createUser,
        updateUser,
        deleteEmpresa,
        toggleEmpresaStatus,
        getAllUsers,
        listUsers,
        pageCount,
        initialListUsers
    };
}