import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { set } from "lodash";

interface UploadResult {
    successFiles: string[];
    failedFiles: string[];
    errors: { [key: string]: string };
}

export default function useFile() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [uploadResults, setUploadResults] = useState<UploadResult | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const uploadFile = async (file: File, network: string, empresa: string, ano: string, mes: string) => {
    //     setLoading(true);
    //     const formData = new FormData();
    //     formData.append("zipFile", file);
    //     formData.append("network", network);
    //     formData.append("empresa", empresa);
    //     formData.append("ano", ano);
    //     formData.append("mes", mes);

    //     try {
    //         const response = await api.post("/file/upload", formData, {
    //             headers: {
    //                 "Content-Type": "multipart/form-data",
    //             },
    //         });
    //         setLoading(false);
    //         console.log(response.data);
    //         // if (response.data.successFiles.length > 0) {
    //         //     showToast(`Arquivos enviados com sucesso: ${response.data.successFiles.join(', ')}`, 'success');
    //         // }
    //         // if (response.data.failedFiles.length > 0) {
    //         //     response.data.failedFiles.forEach((file: string, index: number) => {
    //         //         showToast(`Falha no arquivo ${file}: ${response.data.error[file]}`, 'error');
    //         //     });
    //         // }

    //         if (response.data.successFiles || response.data.failedFiles) {
    //             response.data.successFiles.forEach((file: string) => {
    //                 showToast("success", `Sucesso: ${file}`);
    //             });

    //             response.data.failedFiles.forEach((file: string) => {
    //                 showToast("error",`Falha: ${file} - ${response.data.error[file]}` );
    //             });
    //         }


    //         return response.data;
    //     } catch (error: any) {
    //         setLoading(false);
    //         console.log(error);
    //         const errorMessage = error || "Erro ao enviar arquivo";
    //         showToast("error", errorMessage);
    //         console.error("Erro ao enviar arquivo:", errorMessage);
    //         throw error;
    //     }
    // };

    const uploadFile = async (file: File, network: string, empresa: string, ano: string, mes: string) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("zipFile", file);
        formData.append("network", network);
        formData.append("empresa", empresa);
        formData.append("ano", ano);
        formData.append("mes", mes);

        try {
            const response = await api.post("/file/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setLoading(false);

            setUploadResults(response.data);
            setIsModalOpen(true);

            return response.data;
        } catch (error: any) {
            setLoading(false);
            console.error("Erro ao enviar arquivo:", error.response?.data || error.message);
            showToast("error", error.message || "Erro ao enviar arquivo");
            throw error;
        }
    }
    
    return { 
        uploadFile,
        uploadResults,
        isModalOpen,
        setIsModalOpen
    };
}