import React, { useContext, useEffect, useState, ChangeEvent, useRef } from "react";
import { Container, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Header from "../../components/Headers/Header";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { EmpresasContext } from "../../context/EmpresasContext";
import { NetworksType } from "../../context/hooks/useEmpresas";
import useFile from "../../context/hooks/useFile";

const Database = () => {
    const [loading, setLoading] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState<string>("");
    const [selectedEmpresa, setSelectedEmpresa] = useState<string>("");
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<string>("");
    const [zipFile, setZipFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const fileInputRef = useRef<HTMLInputElement>(null);

    const { listEmpresas, getAllNetworks, getEmpresasByNetwokr, listNetworks } = useContext(EmpresasContext);
    const { uploadFile, uploadResults, isModalOpen, setIsModalOpen } = useFile();

    useEffect(() => {
        getAllNetworks();
    }, []);

    const handleNetworkChange = (networkId: string) => {
        setSelectedNetwork(networkId);
        getEmpresasByNetwokr(networkId);
    };

    // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    //     const file: File | null = event.target.files?.[0] || null;
    //     setZipFile(file);
    // };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file: File | null = event.target.files?.[0] || null;

        if (file) {
            const fileNameWithoutExtension = file.name.split(".")[0];
            const containsNumbers = /\d/.test(fileNameWithoutExtension);

            if (containsNumbers) {
                setErrorMessage("O nome do arquivo não pode conter números. Por favor, renomeie o arquivo.");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            } else {
                setZipFile(file);
                setErrorMessage("");
            }
        }
    };

    const handleSubmit = async () => {
        if (!selectedNetwork || !selectedEmpresa || !selectedMonth || !selectedYear || !zipFile) {
            console.error("Todos os campos são obrigatórios.");
            return;
        }

        setLoading(true);

        try {
            await uploadFile(zipFile, selectedNetwork, selectedEmpresa, selectedYear, selectedMonth);
            console.log("Arquivo enviado com sucesso!");
            resetFields();
        } catch (error) {
            console.error("Erro ao enviar arquivo:", error);
        } finally {
            setLoading(false);
        }
    };

    const resetFields = () => {
        setSelectedNetwork("");
        setSelectedEmpresa("");
        setSelectedMonth("");
        setSelectedYear("");
        setZipFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const monthsOptions = [
        { value: "Janeiro", label: "Janeiro" },
        { value: "Fevereiro", label: "Fevereiro" },
        { value: "Março", label: "Março" },
        { value: "Abril", label: "Abril" },
        { value: "Maio", label: "Maio" },
        { value: "Junho", label: "Junho" },
        { value: "Julho", label: "Julho" },
        { value: "Agosto", label: "Agosto" },
        { value: "Setembro", label: "Setembro" },
        { value: "Outubro", label: "Outubro" },
        { value: "Novembro", label: "Novembro" },
        { value: "Dezembro", label: "Dezembro" },
    ];

    const currentYear = new Date().getFullYear();
    const yearsOptions = Array.from({ length: 10 }, (_, index) => ({ value: `${currentYear - index}`, label: `${currentYear - index}` }));

    return (
        <>
            {loading && <LoadingSpinner />}
            <Header />
            <AdminNavbar brandText={"Banco de Dados"} search={false} titleSearch={""} />
            <Container className="mt-4">
                <FormGroup>
                    <Label for="networkSelect">Selecione a rede:</Label>
                    <Input type="select" className="form-control form-control-alternative" name="network" id="networkSelect" value={selectedNetwork} onChange={(e) => handleNetworkChange(e.target.value)}>
                        <option value="">Selecione uma rede...</option>
                        {listNetworks.map((network: NetworksType) => (
                            <option key={network.name} value={network.name}>
                                {network.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
                {selectedNetwork && (
                    <FormGroup>
                        <Label for="empresaSelect">Selecione a empresa:</Label>
                        <Input type="select" className="form-control form-control-alternative" name="empresa" id="empresaSelect" value={selectedEmpresa} onChange={(e) => setSelectedEmpresa(e.target.value)}>
                            <option value="">Selecione uma empresa...</option>
                            {listEmpresas.map((empresa) => (
                                <option key={empresa.name} value={empresa.name}>
                                    {empresa.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
                {selectedEmpresa && (
                    <>
                        <FormGroup>
                            <Label for="monthSelect">Mês:</Label>
                            <Input type="select" className="form-control form-control-alternative" name="month" id="monthSelect" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                                <option value="">Selecione o mês...</option>
                                {monthsOptions.map((month) => (
                                    <option key={month.value} value={month.value}>
                                        {month.label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="yearSelect">Ano:</Label>
                            <Input type="select" className="form-control form-control-alternative" name="year" id="yearSelect" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                <option value="">Selecione o ano...</option>
                                {yearsOptions.map((year) => (
                                    <option key={year.value} value={year.value}>
                                        {year.label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="zipFile">Selecione um arquivo compactado:</Label>
                            {/* <Input type="file" className="form-control form-control-alternative" name="zipFile" id="zipFile" onChange={handleFileChange} innerRef={fileInputRef} /> */}
                            <Input
                                type="file"
                                className="form-control form-control-alternative"
                                name="zipFile"
                                id="zipFile"
                                onChange={handleFileChange}
                                innerRef={fileInputRef}
                            />
                            {errorMessage && (
                                <div className="text-danger mt-2">{errorMessage}</div>
                            )}
                        </FormGroup>
                        <Button color="primary" style={{ color: "#FFF", backgroundColor: "#1c547c" }} onClick={handleSubmit}>
                            Enviar Arquivo
                        </Button>
                    </>
                )}
            </Container>

            {uploadResults && (
                <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
                    <ModalHeader toggle={() => setIsModalOpen(false)}>Resultados do Upload</ModalHeader>
                    <ModalBody>
                        <h5>Arquivos Processados com Sucesso:</h5>
                        <ul>
                            {uploadResults.successFiles.map((file: string, index: number) => (
                                <li key={index}>
                                    <i className="fas fa-file-alt" style={{ color: 'green', marginRight: '8px' }}></i>
                                    {file}
                                </li>
                            ))}
                        </ul>
                        <h5>Arquivos com Falhas:</h5>
                        <ul>
                            {uploadResults.failedFiles.map((file: string, index: number) => (
                                <li key={index}>
                                    <i className="fas fa-file-alt" style={{ color: 'red', marginRight: '8px' }}></i>
                                    {file}
                                    <div style={{ fontSize: '0.9em', color: 'gray' }}>
                                        {uploadResults.errors[file]}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default Database;
